import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faEye,
	faInfo,
	faRightFromBracket,
	faXmark,
} from '@awesome.me/kit-58ff991e8b/icons/sharp/solid'

library.add(faXmark, faEye, faInfo, faRightFromBracket)
