import * as api from '@/api'
import { Season } from '@/types/Season'
import { UserWeek, Week } from '@/types/Week'

import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { useSeasonStore } from './SeasonStore'

export const useUserSeasonStore = defineStore('userSeason', () => {
	const seasonStore = useSeasonStore()

	async function loadWeeks(seasonId?: Season['id']): Promise<UserWeek[]> {
		let id: string | undefined = undefined

		if (seasonId) {
			id = seasonId
		} else {
			if (seasonStore.state.currentSeason === null) {
				state.value.currentSeason =
					await seasonStore.loadCurrentSeason()
			} else {
				state.value.currentSeason = seasonStore.state.currentSeason
			}

			id = state.value.currentSeason?.id
		}

		if (!id) {
			return []
		}

		try {
			const response = await api.account.seasonWeeksEntries(id)

			if (response.success && response.data) {
				state.value.weeks = response.data
			} else {
				state.value.weeks = []
			}

			return state.value.weeks as UserWeek[]
		} catch (e) {
			console.error('error loading user season weeks')

			return []
		}
	}

	const currentWeek: ComputedRef<Week | any> = computed(() => {
		return (
			state.value.weeks?.find((wk) => {
				return wk.id === state.value.currentSeason?.weeks?.current.id
			}) || null
		)
	})

	const state = ref<{
		currentSeason: Season | null
		weeks: UserWeek[]
		currentWeek: Week | any
	}>({
		currentSeason: null,
		weeks: [],
		currentWeek,
	})

	return {
		state,
		loadWeeks,
	}
})
