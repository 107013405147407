<template>
	<article class="relative">
		<FontAwesomeIcon
			:icon="[prefixMap[props.iconStyle], props.icon]"
			@click="isOpen = !isOpen"
			class="cursor-pointer transition-all hover:scale-110"
		/>

		<modal
			v-if="isOpen"
			:close-on-click-away="true"
			@close="isOpen = false"
		>
			<div class="bg-white rounded-xl overflow-hidden">
				<div
					v-if="props.title"
					class="bg-gray-300 p-2 px-4 flex items-center gap-2"
				>
					<FontAwesomeIcon
						class="block"
						:icon="[prefixMap[props.iconStyle], props.icon]"
						@click="isOpen = !isOpen"
					/>

					<h2 class="font-bold">{{ props.title }}</h2>

					<button
						@click="isOpen = false"
						class="ml-auto flex items-center gap-2 hover:underline"
					>
						Close <FontAwesomeIcon icon="times" />
					</button>
				</div>

				<div class="p-4 relative">
					<slot></slot>
				</div>
			</div>
		</modal>
	</article>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Modal from './Modal/Modal.vue'

const isOpen = ref(false)

const prefixMap = {
	solid: 'fas',
	regular: 'far',
	light: 'fal',
	thin: 'fat',
	duotone: 'fad',
	sharp: 'fass',
}

const props = withDefaults(
	defineProps<{
		icon?: string
		title?: string
		iconStyle: keyof typeof prefixMap
	}>(),
	{
		iconStyle: 'solid',
		icon: 'info-circle',
	},
)
</script>
