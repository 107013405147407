<template>
	<layout-main :show-header="true">
		<div
			v-if="showDirections"
			class="m-4 p-4 bg-teal-800/10 rounded-xl text-teal-800 z-0 relative hidden md:block"
		>
			<button
				class="z-10 absolute right-3 top-2 text-teal-800"
				@click="showDirections = !showDirections"
			>
				<span>
					<fa icon="fa-solid fa-circle-xmark" />
				</span>
			</button>
			<p><strong>Directions:</strong></p>
			<p class="text-sm">
				Pick six heroes that you think will score <em>over</em> their
				points projection this week. If all six of your selected heroes
				succeed, you will win 2,000 for each entry you submit with the
				same lineup!
			</p>
			<p class="text-xs mt-4">
				* Projections include all games from Thursday through Monday
			</p>
		</div>
		<p v-if="!showDirections" class="text-right hidden md:block">
			<button
				class="text-sm text-teal-800 tracking-tight"
				@click="showDirections = !showDirections"
			>
				Help
				<fa icon="fa-regular fa-circle-question" />
			</button>
		</p>

		<div class="md:grid grid-cols-[6fr,5fr] z-0 w-full">
			<SaveTicketModal
				v-if="showSaveModal"
				:heroes="heroSlots"
				:used-power-play="hasUsedPowerPlay"
				:entry-limit="entryLimit"
				:save-handler="saveEntry"
				@close="showSaveModal = false"
				@reset="reset()"
			/>

			<div class="sticky top-16 z-20 md:z-0 -order-1 md:h-full">
				<div
					class="bg-gray-100 md:bg-transparent shadow md:shadow-none"
				>
					<div class="p-4 pb-0 flex justify-between items-end">
						<h1 class="text-teal-800 text-2xl font-medium">
							Your Picks
						</h1>
						<p class="text-teal-800 text-sm">
							<span v-if="selectionCount < selectionLimit">
								Choose
								{{ selectionLimit - selectionCount }} more
								hero{{
									selectionLimit - selectionCount != 1
										? 'es'
										: ''
								}}
							</span>
							<button
								v-else
								class="button !py-1"
								@click="showSaveModal = true"
							>
								Confirm your picks
							</button>
						</p>
					</div>
					<div
						class="grid grid-cols-6 gap-4 md:block md:space-y-7 p-4"
					>
						<div
							v-for="idx in selectionLimit"
							:key="`selected-${idx - 1}`"
							class="flex flex-row space-x-4 cursor-pointer w-full"
							@click="selectSlot(idx - 1)"
						>
							<div
								class="relative max-w-28 md:max-w-none aspect-square w-full bg-gray-300 shadow flex justify-center items-center border-2 rounded md:w-2/6 md:mb-4"
								:class="[
									idx - 1 == currentSlot
										? 'border-teal-800'
										: 'border-transparent',
								]"
							>
								<div
									v-if="heroSlots[idx - 1] === null"
									class="hidden opacity-25 md:block absolute w-full h-full"
									:style="`background-image: url('/img/unpicked.png'); background-size: cover; background-position: center;`"
								></div>
								<fa
									v-if="heroSlots[idx - 1] === null"
									icon="fa-solid fa-plus"
									class="drop-shadow-md text-white"
								/>
								<img
									v-else
									:src="heroSlots[idx - 1]?.image_url"
									class="rounded"
								/>

								<button
									v-if="heroSlots[idx - 1]"
									class="absolute -right-2 -top-2 bg-red-300 text-white rounded-full text-xs aspect-square w-5 h-5"
									@click="clearSlot(idx - 1)"
								>
									<fa icon="fa-solid fa-minus" />
								</button>
							</div>
							<div class="hidden md:block z-0">
								<p class="font-bold text-gray-600">
									Pick # {{ idx }}
								</p>
								<template v-if="heroSlots[idx - 1] !== null">
									<p class="text-xl font-bold">
										{{
											heroSlots[idx - 1]?.hero_name ?? ''
										}}
									</p>
									<p class="text-sm text-gray-500">
										Favorite player:
										{{
											heroSlots[idx - 1]?.player_name ??
											''
										}}
									</p>
									<p class="tracking-tight text-gray-500">
										<span class="text-sm">Projected: </span>
										<span
											>{{
												heroSlots[idx - 1]
													?.projected_points
											}}
											pts</span
										>
									</p>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="relative h-full">
				<h1 class="mt-4 text-2xl font-medium text-teal-800 px-4 md:p-0">
					Choose Your Heroes
				</h1>
				<div
					class="sticky top-96 md:top-20 md:mt-4 md:border-2 md:border-gray-300 rounded-xl md:shadow overflow-hidden"
				>
					<div
						id="input-container-row"
						class="flex justify-between items-center p-4 space-x-2 md:top-0 bg-white z-20 border-b border-gray-100 shadow"
					>
						<div class="flex flex-col w-full">
							<div class="flex flex-col space-y-2 mb-4">
								<label
									for="sport-filter"
									class="text-teal-800 font-medium"
								>
									Filter by Sport
								</label>
								<div class="flex flex-wrap gap-2">
									<button
										v-for="sport in allSports"
										:key="sport"
										@click="setSportFilter(sport)"
										:class="[
											'px-3 py-1 rounded-lg text-sm font-medium',
											selectedSport === sport
												? 'bg-teal-800 text-white'
												: 'bg-gray-200 text-gray-600 hover:bg-teal-100',
										]"
									>
										{{ sport || 'All' }}
									</button>
								</div>
							</div>
							<div
								class="flex-grow flex justify-between items-center space-x-8"
							>
								<div
									class="flex items-center flex-grow shadow border rounded-lg"
								>
									<!-- input container -->
									<label
										for="filter"
										class="bg-gray-100 text-gray-400 px-2 py-1.5"
									>
										<fa icon="fa-solid fa-search" />
									</label>
									<p class="flex-grow">
										<input
											ref="filter"
											v-model="fuzzySearch"
											id="filter"
											type="text"
											placeholder="Hero filter"
											class="w-full focus:outline-teal-800 px-2 py-1.5"
											@keydown="navigateResults"
											@focusout="
												searchHighlightedElement = -1
											"
										/>
									</p>
									<p class="px-2">
										<button
											class="text-gray-300 enabled:hover:text-red-600"
											@click="fuzzySearch = ''"
											:disabled="fuzzySearch.length == 0"
										>
											<fa icon="fa-solid fa-xmark" />
										</button>
									</p>
								</div>
							</div>
						</div>

						<button
							class="text-sm text-teal-800 underline tracking-tight md:hidden"
							@click="showDirections = !showDirections"
						>
							{{ showDirections ? 'Hide ' : 'Show ' }} Directions
						</button>
					</div>

					<div></div>
					<div class="md:max-h-[75vh] md:overflow-y-auto">
						<div
							v-if="showDirections"
							class="m-4 p-4 bg-teal-800/10 rounded-xl text-teal-800 relative md:hidden"
						>
							<button
								class="z-10 absolute right-3 top-2 text-teal-800"
								@click="showDirections = !showDirections"
							>
								<span>
									<fa icon="fa-solid fa-circle-xmark" />
								</span>
							</button>
							<p><strong>Directions:</strong></p>
							<p class="text-sm">
								Pick six heroes that you think will score
								<em>over</em> their points projection this week.
								If all six of your selected heroes succeed, you
								will win 2,000 for each entry you submit with
								the same lineup!
							</p>
						</div>

						<section class="flex flex-col">
							<div
								class="mt-4 md:mt-0 md:flex flex-wrap space-y-4"
							>
								<create-entry-hero-row
									v-for="(hero, idx) in filteredHeroes"
									:key="`hero-${hero.id}`"
									class="flex justify-start space-x-4"
									:hero="hero"
									:heroList="filteredHeroes"
									:disabled="isDisabled(hero)"
									:highlighted="
										idx == searchHighlightedElement
									"
									:selected="heroIndex(hero) >= 0"
									@add="addHero"
									@remove="removeHero"
									@updateModalStatus="updateModalStatus"
								/>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
		<template #center-button>
			<button
				:disabled="currentSlot >= 0"
				class="bg-teal-800 text-white w-full aspect-square rounded-full shadow-md shadow-gray-400 disabled:bg-gray-300 disabled:text-gray-400"
				@click="showSaveModal = true"
			>
				<span class="text-lg">
					<fa icon="fa-solid fa-check" />
				</span>
			</button>
		</template>

		<div>
			<PlayerModal
				v-if="isModalOpen"
				:hero="currentHero"
				:isDisabled="isDisabledModal"
				:heroList="filteredHeroes"
				:selected="heroIndex(currentHero) >= 0"
				:currentIndex="currentIndex"
				:createModal="true"
				@close="closeModal"
				@change="changePlayer"
				@add="addHero"
				@remove="removeHero"
				:is-selection-limit-reached="isSelectionLimitReached"
			/>
		</div>
	</layout-main>
</template>
<script lang="ts" setup>
import PlayerModal from '@/components/Heroes/PlayerStatsModal.vue'
import CreateEntryHeroRow from '@/components/Heroes/CreateEntryHeroRow.vue'
import SaveTicketModal from '@/components/Modal/SaveTicketModal.vue'
import { useLogger } from '@/modules/log'
import { useAuthStore } from '@/stores/AuthStore'
import { Entry, ListEntryResponse } from '@/types/Entry'
import { usePickSixStore } from '@/stores/PickSixStore'
import { SaveEntryResponse } from '@/types/Entry'
import { Hero, WeekHero } from '@/types/Hero'
import { Season } from '@/types/Season'
import { Rank } from '@/types/User'
import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { DateTime } from 'ts-luxon'
import { useSeasonStore } from '@/stores/SeasonStore'

type heroMap = { [idx: number]: Hero | null }
const pickSixStore = usePickSixStore()
const log = useLogger()
const authStore = useAuthStore()

const selectionLimit = 6
const showDirections = ref<boolean>(true)
const selectedHeroes = ref<heroMap>({} as heroMap)
const currentSlot = ref<number>(0)
const fuzzySearch = ref('')
const selectedSport = ref<string>('')
const searchHighlightedElement = ref<number>(-1)
const showSaveModal = ref(false)
const filter = ref<HTMLElement | null>(null)

const heroSlots = ref<WeekHero[] | null[]>(new Array(6).fill(null))
const entryLimit = ref<number>(1)

const season = ref<Season | null>(null)
const entries = ref<Entry[] | null>(null)
const minDesktopWidth = 768

const seasonStore = useSeasonStore()

onBeforeMount(async () => {
	if (seasonStore.state.currentSeason) {
		season.value = seasonStore.state.currentSeason
	} else {
		await seasonStore.loadCurrentSeason()
		season.value = seasonStore.state.currentSeason
	}

	await loadEntries(season.value!.slug, season.value!.weeks.current.id)

	setEntryLimit()
})

onBeforeUnmount(async () => {
	await authStore.getAccount()
})

const selectedHeroMap = computed(() => {
	let hMap: { [name: string]: number } = {}
	for (let i = 0; i < selectionLimit; i++) {
		if (selectedHeroes.value[i]) {
			const h = selectedHeroes.value[i]
			hMap[h!.hero_name] = i
		}
	}

	return hMap //
})

const isModalOpen = ref(false)
const currentIndex = ref(0)

function updateModalStatus(heroIndex: number, isOpen: boolean) {
	isModalOpen.value = isOpen
	currentIndex.value = heroIndex
}

async function loadEntries(
	seasonSlug: string,
	weekId: string,
	countOnly = false,
) {
	await pickSixStore
		.loadEntries(seasonSlug, weekId)
		.then((response: ListEntryResponse) => {
			if (response.success) {
				if (countOnly) {
					return response.entries.length
				}
				entries.value = response.entries!
				return
			}
			throw new Error('Failed to load user entries')
		})
		.catch((err) => {
			log.error('ViewEntries: failed to load user data', {
				err: err.message,
				season: season.value,
				entries: entries.value,
			})
		})
}

const currentHero = computed(() => {
	return filteredHeroes.value[currentIndex.value] || null
})

const isDisabledModal = computed(() => {
	return (
		selectedHeroMap.value[
			filteredHeroes.value[currentIndex.value].hero_name
		] >= 0 ||
		currentSlot === null ||
		filteredHeroes.value[currentIndex.value].actual_points !== null ||
		filteredHeroes.value[currentIndex.value].projected_points === 0
	)
})

const allSports = computed(() => {
	if (!season.value) return []

	const heroes = season.value.weeks?.current?.heroes
	if (!Array.isArray(heroes)) {
		return ['All']
	}

	const sports = heroes.map((hero) => hero.sport.toUpperCase())
	return ['All', ...new Set(sports)]
})

function setSportFilter(sport: string) {
	selectedSport.value = sport === 'All' ? null : sport
}

function closeModal() {
	isModalOpen.value = false
	document.body.style.overflow = '' // Enable scrolling
}

function changePlayer(index: number) {
	currentIndex.value = index
}

const selectionCount = computed(() => {
	return heroSlots.value.filter((x) => x !== null).length
})

const hasUsedPowerPlay = computed(() => {
	return entries.value.some(
		(entry) => entry.is_power_play === 1 || entry.is_power_play === true,
	)
})

const filteredHeroes = computed(() => {
	if (!season.value) return []

	return season.value
		.weeks!.current!.heroes!.filter((hero) => {
			if (
				selectedSport.value &&
				hero.sport.toUpperCase() !== selectedSport.value
			) {
				return false
			}
			return (
				!fuzzySearch.value.trim() ||
				hero.hero_name
					.toLowerCase()
					.includes(fuzzySearch.value.toLowerCase()) ||
				hero.player_name
					.toLowerCase()
					.includes(fuzzySearch.value.toLowerCase())
			)
		})
		.sort((a, b) => {
			const aDisabled = isDisabled(a)
			const bDisabled = isDisabled(b)

			if (aDisabled && !bDisabled) return 1
			if (!aDisabled && bDisabled) return -1

			if (a.actual_points === null && b.actual_points) return -1
			if (b.actual_points === null && a.actual_points) return 1

			if (a.actual_points! < b.actual_points!) {
				return -1
			}

			if (a.actual_points! > b.actual_points!) {
				return 1
			}

			return 0
		})
})

function isDisabled(hero: WeekHero) {
	return (
		selectedHeroMap.value[hero.hero_name] >= 0 ||
		currentSlot === null ||
		hero.actual_points !== null ||
		hero.projected_points === 0 ||
		hero.first_game_of_week === null ||
		DateTime.fromSQL(hero.first_game_of_week.toString()) <= DateTime.now()
	)
}

function onHeroAdded(heroIdx: number) {
	if (filledSlotCount.value == selectionLimit) {
		showSaveModal.value = true
		isModalOpen.value = false
	}

	// check screen size and touch feature to check if we should focus
	// the search input to enable keyboard navigation for desktop users
	if (
		!(
			window.innerWidth < minDesktopWidth ||
			screen.width < minDesktopWidth
		) &&
		!('ontouchstart' in window || navigator.maxTouchPoints > 0)
	) {
		filter.value!.focus()
	}

	searchHighlightedElement.value = heroIdx
}

function onHeroRemoved() {
	// do nothing for now
}

const filledSlotCount = computed(() => {
	return heroSlots.value.filter((v: Hero | null) => v !== null).length
})

function addHero(hero: WeekHero) {
	if (filledSlotCount.value == selectionLimit) {
		log.debug('selection limit reached')
		showSaveModal.value = true
		return
	}

	if (heroIndex(hero) >= 0) {
		log.debug('hero already selected')
		return
	}

	heroSlots.value[currentSlot.value] = hero
	// let heroSlot = currentSlot.value
	currentSlot.value = nextEmptySlot()

	onHeroAdded(filteredHeroes.value.findIndex((h) => h.id === hero.id))
}

function removeHero(hero: Hero) {
	heroSlots.value.forEach((h, idx) => {
		if (h && h.id == hero.id) {
			clearSlot(idx)
			onHeroRemoved()
			currentSlot.value = nextEmptySlot()
		}
	})
}

function clearSlot(slot: number) {
	if (slot > selectionLimit - 1 || slot < 0) {
		return
	}

	let hero = heroSlots.value[slot]
	if (hero) {
		heroSlots.value[slot] = null
		onHeroRemoved()
	}

	currentSlot.value = nextEmptySlot()
}

function nextEmptySlot(): number {
	let emptySlots: number[] = [] as number[]
	for (let x = selectionLimit - 1; x >= 0; x--) {
		if (heroSlots.value[x] === null) {
			emptySlots.push(x)
		}
	}

	if (emptySlots.length) {
		return Math.min(...emptySlots)
	}

	for (let x = 0; x < selectionLimit; x++) {
		if (heroSlots.value[x] === null) {
			return x
		}
	}

	return -1
}

function selectSlot(slot: number) {
	currentSlot.value = slot
}

function navigateResults($evt: KeyboardEvent) {
	const keyEvents = ['ArrowUp', 'ArrowDown', 'Enter']
	if (keyEvents.includes($evt.code)) {
		$evt.preventDefault()
	}

	if ($evt.code == 'ArrowUp') {
		if ((searchHighlightedElement.value ?? 0) - 1 < 0) {
			searchHighlightedElement.value = filteredHeroes.value.length - 1
		} else {
			searchHighlightedElement.value = Math.max(
				0,
				(searchHighlightedElement.value ?? 0) - 1,
			)
		}
	} else if ($evt.code == 'ArrowDown') {
		if (
			(searchHighlightedElement.value ?? 0) + 1 >
			filteredHeroes.value.length - 1
		) {
			searchHighlightedElement.value = 0
		} else {
			searchHighlightedElement.value = Math.min(
				filteredHeroes.value.length - 1,
				(searchHighlightedElement.value ?? 0) + 1,
			)
		}
	} else if ($evt.code == 'Enter') {
		if (searchHighlightedElement.value >= 0) {
			const h = filteredHeroes.value[searchHighlightedElement.value]

			if (
				selectedHeroMap.value[h.hero_name] >= 0 ||
				currentSlot.value === null ||
				h.actual_points !== null ||
				h.projected_points === 0
			) {
				return
			}

			if (heroIndex(h) >= 0) {
				removeHero(h)
			} else {
				addHero(h, searchHighlightedElement.value)
			}
		}
	}

	// pushing this to the next tick, because the filtered heroes gets modified after this code is executed
	nextTick(() => {
		if (searchHighlightedElement.value > filteredHeroes.value.length) {
			searchHighlightedElement.value = 0
		}
	})
}

function heroIndex(hero: Hero): number {
	for (let i = 0; i < heroSlots.value.length; i++) {
		if (heroSlots.value[i] && heroSlots.value[i]?.id == hero.id) {
			return i
		}
	}

	return -1
}

function saveEntry(
	entries: number,
	powerPlay: boolean,
): Promise<SaveEntryResponse> {
	const heroIds: string[] = heroSlots.value.map((hero) => {
		if (hero) {
			return hero.id
		} else {
			return ''
		}
	})

	return pickSixStore.createEntry(
		season.value!.id,
		season.value!.weeks!.current.id,
		heroIds,
		entries,
		powerPlay,
	)
}

async function reset() {
	await authStore.getAccount().then(() => {
		setEntryLimit()
	})
	heroSlots.value = new Array(6).fill(null)
	currentSlot.value = nextEmptySlot()
	showSaveModal.value = false
}

async function setEntryLimit() {
	const currentWeekRank = authStore.user?.rankings.find((rank: Rank) => {
		return rank.week_id === season.value?.weeks?.current.id
	})

	currentWeekRank?.entries_allowed
		? (entryLimit.value =
				currentWeekRank.entries_allowed -
				currentWeekRank.total_entry_count)
		: (entryLimit.value = 1)
}

const isSelectionLimitReached = computed(() => {
	return filledSlotCount.value === selectionLimit
})
</script>
