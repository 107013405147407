// Convert Bipixies (no decimals) to BPX (9 decimal places)

function toFloat(value: bigint): number {
	const integralPart = value / BigInt(1e9)
	const decimalPart = Number(value % BigInt(1e9)) / 1e9
	return Number(integralPart) + decimalPart
}

export function bpxToMiles(bpx: number | string): number {
	return toFloat(BigInt(bpx))
}
