<template>
	<template v-if="userSeasonStore.state.weeks.length === 0">
		<fa icon="fa fa-spinner" spin class="text-3xl" />
	</template>
	<template v-else>
		<p class="text-lg md:text-xl font-bold text-gray-800">
			{{ $format(earnedTotal) }} {{ $token }}
		</p>

		<div class="relative bg-green-200 rounded-full mt-2 h-5">
			<div
				class="bg-green-600 rounded-l-full absolute top-0 left-0 h-full flex items-center justify-center"
				:style="`width:${progressPercentage}%`"
			>
				<p class="text-xs text-white px-2">
					{{ `${progressPercentage}%` }}
				</p>
			</div>
			<div
				class="bg-green-100 rounded-r-full absolute top-0 right-0 h-full flex items-center justify-center"
				:style="`width:${((userSeasonStore.state.weeks.length - weeksParticipated) / userSeasonStore.state.weeks.length) * 100}%`"
			></div>
		</div>
	</template>
</template>
<script lang="ts" setup>
import { computed, ComputedRef, onBeforeMount, ref } from 'vue'
import { bpxToMiles } from '@/util/numbers'
import { UserWeek } from '@/types/Week'

import { useUserSeasonStore } from '@/stores/UserSeasonStore'
import { useAuthStore } from '@/stores/AuthStore'

onBeforeMount(() => {
	if (userSeasonStore.state.weeks.length === 0) {
		userSeasonStore.loadWeeks()
	}
})

const userSeasonStore = useUserSeasonStore()
const accountStore = useAuthStore()

interface UserRank {
	week_id: string
	rank: number
	miles_balance: string | number
	entries_allowed: number
	total_entry_count: number
}

const isShowUnplayedWeeks = ref(false)

const userRanks: ComputedRef<UserRank[]> = computed(() => {
	return accountStore.user?.rankings || []
})

const weeks: ComputedRef<UserWeek[]> = computed(() => {
	if (isShowUnplayedWeeks.value) {
		return userSeasonStore.state.weeks || []
	}
	return (
		userSeasonStore.state.weeks.filter((wk) => wk.entries.length > 0) || []
	)
})

const earnedMilesByWeek = computed(() => {
	return weeks.value.reduce((milesByWeek: any, week: UserWeek) => {
		if (week.entries?.length) {
			milesByWeek[week.id] = calcParticipationBonusEarned(week.id)
		}
		return milesByWeek
	}, {})
})

const earnedTotal = computed(() => {
	return Object.values(
		earnedMilesByWeek.value as { [key: string]: number },
	).reduce(
		(totalEarnedMiles: number, earnedMiles: number) =>
			totalEarnedMiles + earnedMiles,
		0,
	)
})

function calcParticipationBonusEarned(weekId: string): number {
	const balance =
		userRanks.value.find((rank: UserRank) => rank.week_id === weekId)
			?.miles_balance || 0

	return Math.ceil((bpxToMiles(balance) * 0.3) / 52)
}

const weeksParticipated = computed(() => {
	return (
		userRanks.value.filter((rank) => rank.total_entry_count > 0).length || 0
	)
})

const progressPercentage = computed(() => {
	return Math.floor(
		(weeksParticipated.value / userSeasonStore.state.weeks.length) * 100,
	)
})
</script>
