<template>
	<layout-main :show-header="true">
		<section class="p-4">
			<div class="flex flex-col sm:flex-row items-center gap-4 flex-wrap">
				<img
					:src="`https://id.blokpax.com/avatar/@${authStore.user.username}?size=128`"
					:alt="`@${authStore.user.username}`"
					class="w-16 h-16 md:w-20 md:h-20 rounded-full border-4 border-gray-500"
				/>
				<div>
					<p
						class="text-2xl md:text-4xl font-bold text-gray-800 text-left"
					>
						@{{ authStore.user.username }}
					</p>
				</div>

				<div
					class="ml-auto w-full sm:w-auto flex flex-row gap-8 text-center justify-center"
				>
					<div class="">
						<div class="">
							<fa
								icon="trophy"
								class="text-yellow-500 inline-block"
							/>
							<p class="text-gray-500 font-medium">
								Rank
								<tool-tip
									icon="circle-question"
									class="inline-block text-sm"
									title="Your Rank"
								>
									Your rank is determined by the points earned
									from the active HTH season(s) collection on
									the
									<a
										href="https://market.blokpax.com/"
										rel="external"
										target="_blank"
										>Blokpax Marketplace</a
									>.
								</tool-tip>
							</p>
						</div>
						<div class="text-lg md:text-xl font-bold text-gray-800">
							{{ currentRank }}
						</div>
					</div>

					<div>
						<div>
							<fa icon="comet" class="text-yellow-500" />
							<p class="text-gray-500 font-medium">
								Streak

								<tool-tip
									icon="circle-question"
									class="inline-block text-sm"
									title="Highest Streak"
								>
									This is the highest Winning streak you have
									achieved in Pick 6.
								</tool-tip>
							</p>
						</div>
						<div class="text-lg md:text-xl font-bold text-gray-800">
							{{ accountDetails?.account.streak }}
						</div>
					</div>
				</div>
			</div>

			<div
				class="text-center sm:grid grid-cols-2 mt-4 gap-4 max-sm:space-y-4"
			>
				<div class="bg-teal-800/10 rounded-lg p-4">
					<div>
						<p class="text-gray-500 font-medium">
							Miles Earned
							<tool-tip
								icon="circle-question"
								class="inline-block text-sm"
								title="Miles Earned"
							>
								<p>
									You've earned
									<strong
										>{{ $format(earnedTotal) }}
										{{ $token }}</strong
									>
									for participating in Pick 6 for
									<strong
										>{{ weeksParticipated }} weeks</strong
									>
								</p>

								<template
									v-if="
										weeksParticipated <
										userSeasonStore.state.weeks.length
									"
								>
									<p class="mt-4">
										You've
										<strong
											>missed
											{{
												userSeasonStore.state.weeks
													.length - weeksParticipated
											}}
											weeks</strong
										>, which means you've missed out on
										<strong
											>{{
												$format(
													totalPotentialBonus -
														earnedTotal,
												)
											}}
											{{ $token }}</strong
										>
										worth of potential earnings, from a
										total of
										<strong
											>{{ $format(totalPotentialBonus) }}
											{{ $token }}</strong
										>
									</p>

									<p class="mt-4">
										Be sure to participate every week to
										maximize your earnings!
									</p>
								</template>

								<template v-else>
									<p class="mt-4">
										Good job! You're maximizing your
										earnings! Keep it up!
									</p>
								</template>

								<p class="mt-4">
									* You can claim your earned miles at
									<a
										href="https://blokpax.com/account/drips"
										target="_blank"
										rel="external"
									>
										https://blokpax.com/account/drips
									</a>
								</p>
							</tool-tip>
						</p>
					</div>
					<div class="">
						<miles-earned />
					</div>
				</div>

				<div class="bg-teal-800/10 rounded-lg p-4">
					<div>
						<p class="text-gray-500 font-medium">
							Miles Won
							<tool-tip
								icon="circle-question"
								class="inline-block text-sm"
								title="Miles Won"
							>
								<p>
									This is your total miles won playing Pick 6
									this season.
								</p>
							</tool-tip>
						</p>
					</div>
					<div class="text-lg md:text-xl font-bold text-gray-800">
						<template v-if="!accountDetails">
							<fa icon="fa fa-spinner" spin />
						</template>
						<template v-else>
							{{ accountDetails?.account.milesWon }}
						</template>
					</div>
				</div>
			</div>
		</section>

		<div class="mt-12">
			<h2
				class="text-2xl md:text-3xl font-semibold text-gray-900 mb-2 text-center md:text-left"
			>
				Your Top Heroes
			</h2>
			<div class="mb-6 font-medium text-sm text-center md:text-left">
				(Win/Pick Rate)
			</div>
			<div class="relative">
				<button
					@click="scrollLeft"
					class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full"
				>
					&larr;
				</button>

				<div
					class="flex overflow-x-auto space-x-4 md:space-x-6 snap-x snap-mandatory scroll-pl-6"
					ref="heroContainer"
				>
					<div
						v-for="hero in topHeroes"
						:key="hero.id"
						class="w-40 p-4 bg-white rounded-lg shadow-md flex-shrink-0 snap-center transition-transform"
					>
						<img
							:src="hero.image_url"
							:alt="hero.hero_name"
							class="w-full h-36 md:h-40 object-cover rounded-md mb-2 md:mb-4"
						/>
						<div
							class="font-medium text-base md:text-lg text-gray-900"
						>
							{{ hero.hero_name }}
						</div>
						<div class="text-gray-500">
							Win Rate:
							<span class="font-bold text-green-600"
								>{{
									accountDetails?.account.heroWinPickRate[
										hero.hero_name
									].rate
								}}%</span
							>
						</div>
					</div>
				</div>

				<button
					@click="scrollRight"
					class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-2 rounded-full"
				>
					&rarr;
				</button>
			</div>
		</div>

		<div class="mt-12"></div>
	</layout-main>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref, ComputedRef, computed } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { usePickSixStore } from '@/stores/PickSixStore'
import { Rank, User } from '@/types/User'
import { AccountDetails } from '@/types/AccountDetails'
import { Season, SeasonResponse } from '@/types/Season'
import { UserWeek, Week, WeeksArray } from '@/types/Week'
import { useSeasonStore } from '@/stores/SeasonStore'

import MilesEarned from '../MilesEarned.vue'
import ToolTip from '../ToolTip.vue'
import { useUserSeasonStore } from '@/stores/UserSeasonStore'
import { bpxToMiles } from '@/util/numbers'

const authStore = useAuthStore()
const pickSixStore = usePickSixStore()
const seasonStore = useSeasonStore()
const season = ref<Season | null>(null)
const user = ref<User | null>(null)
const viewingWeekId = ref<string>('')
const accountDetails = ref<AccountDetails | null>(null)
const userSeasonStore = useUserSeasonStore()

const currentWeekRank: ComputedRef<Rank | undefined> = computed(() => {
	if (!user.value) {
		return undefined
	}

	return user.value.rankings.find((rank: Rank) => {
		return rank.week_id === viewingWeekId.value
	})
})

const heroContainer = ref(null)

const scrollLeft = () => {
	const container = heroContainer.value
	if (container) {
		container.scrollBy({
			left: -container.offsetWidth / 3,
			behavior: 'smooth',
		})
	}
}

const scrollRight = () => {
	const container = heroContainer.value
	if (container) {
		container.scrollBy({
			left: container.offsetWidth / 3,
			behavior: 'smooth',
		})
	}
}

const currentRank: ComputedRef<number | string> = computed(() => {
	return Number(currentWeekRank.value?.rank) || 'Unranked'
})

async function loadUser(): Promise<User | null> {
	return authStore.getAccount().then((u) => {
		if (u) {
			user.value = u
			return u
		}
		return null
	})
}

async function loadDetails(): Promise<AccountDetails | null> {
	return pickSixStore.accountDetails().then((d) => {
		if (d) {
			accountDetails.value = d
			return d
		}
		return null
	})
}

const heroWinPickRate = computed(() => {
	return accountDetails.value
		? accountDetails.value.account.heroWinPickRate
		: {}
})
const topHeroes = computed(() => {
	if (!season.value || !season.value!.weeks!.current) {
		return []
	}

	const heroes = season.value!.weeks!.current!.heroes

	return Object.keys(heroWinPickRate.value)
		.map((heroName) => {
			const heroDetails = heroes.find(
				(hero) => hero.hero_name === heroName,
			)
			if (heroDetails) {
				return {
					...heroDetails,
					winRate: heroWinPickRate[heroName],
				}
			}
			return null
		})
		.filter((hero) => hero !== null)
		.sort((a, b) => b.winRate - a.winRate)
		.slice(0, 10)
})

const currentWeek: ComputedRef<Week | any> = computed(() => {
	if (season.value?.weeks == undefined) {
		return {}
	}

	if (season.value?.weeks.current == undefined) {
		return {}
	}

	const week = Object.values(season.value?.weeks!).find((week: Week) => {
		if (week.id === viewingWeekId.value) {
			return week
		}
	})
	viewingWeekId.value = week ? week.id : season.value?.weeks.current.id
	return week ? week : season.value?.weeks.current
})

onMounted(async () => {
	if (!authStore.authenticated) {
		await authStore.getAccount()
	}
})

onBeforeMount(async () => {
	await loadUser()

	if (seasonStore.state.currentSeason) {
		season.value = seasonStore.state.currentSeason
	} else {
		await seasonStore.loadCurrentSeason()
		season.value = seasonStore.state.currentSeason
	}

	let week
	if (currentWeek.value.week_number) {
		week = Object.values(season.value!.weeks as WeeksArray).find(
			(week: Week) => {
				return (
					week.week_number === Number(currentWeek.value.week_number)
				)
			},
		)
	}
	if (week) {
		viewingWeekId.value = week.id
	}

	await loadDetails()
})

const userRanks: ComputedRef<Rank[]> = computed(() => {
	return authStore.user?.rankings || []
})

const weeks: ComputedRef<UserWeek[]> = computed(() => {
	return userSeasonStore.state.weeks || []
})

const earnedPointsByWeek = computed(() => {
	return weeks.value.reduce((pointsByWeek: any, week: UserWeek) => {
		if (week.entries?.length) {
			pointsByWeek[week.id] = calcParticipationBonusEarned(week.id)
		}
		return pointsByWeek
	}, {})
})

const earnedTotal = computed(() => {
	return Object.values(earnedPointsByWeek.value).reduce(
		(acc: number, points: number) => acc + points,
		0,
	)
})

function calcParticipationBonusEarned(weekId: string): number {
	const balance =
		userRanks.value.find((rank: Rank) => rank.week_id === weekId)
			?.miles_balance || 0

	return Math.ceil((bpxToMiles(balance) * 0.3) / 52)
}

const weeksParticipated = computed(() => {
	return (
		userRanks.value.filter((rank) => rank.total_entry_count > 0).length || 0
	)
})

const totalPotentialBonus = computed(() => {
	return (
		userRanks.value.reduce(
			(acc, rank) => acc + calcParticipationBonusEarned(rank.week_id),
			0,
		) || 0
	)
})
</script>
