<template>
	<div
		class="text-center bg-gray-100 p-4 rounded-lg"
		v-if="props.userRank && props.userRank.miles_balance > 0"
	>
		<template v-if="props.userRank.total_entry_count === 0">
			<template v-if="isCurrentWeek">
				<p class="text-xl">Make your first entry!</p>
				<p>
					<span v-if="participationMiles > 100">
						Make your first entry to earn
						<strong
							>{{ $format(participationMiles) }}
							{{ $token }}</strong
						>!
					</span>
					<span v-else>
						Every week you play earns you more miles at the end of
						the season.
					</span>
				</p>
			</template>
			<template v-else>
				<p class="text-xl">You missed out!</p>
				<p>
					You could have earned
					<strong
						>{{ $format(participationMiles) }} {{ $token }}</strong
					>
					by playing this week.
				</p>
			</template>
		</template>
		<template v-else>
			<p>
				You've earned a weekly bonus of
				<strong>{{ $format(participationMiles) }} {{ $token }}</strong>
				for playing this week!
			</p>
		</template>
	</div>
</template>
<script lang="ts" setup>
import { defineProps, computed } from 'vue'
import { bpxToMiles } from '@/util/numbers'
import { Rank } from '@/types/User'
import { useSeasonStore } from '@/stores/SeasonStore'

const seasonStore = useSeasonStore()
const props = defineProps<{
	userRank: Rank
}>()

const participationMiles = computed(() => {
	return Math.ceil((bpxToMiles(props.userRank.miles_balance) * 0.3) / 52)
})

const isCurrentWeek = computed(() => {
	return seasonStore?.currentWeek?.id === props.userRank.week_id
})
</script>
